import React from 'react'

import './Animation.css'

const Animation = (props) => {
  return (
    <div className='animation-wrapper'>
      <div className='animation-bar top'>

      </div>
      <div className='animation-bar right'>

      </div>
      {/* <div className='animation-bar bottom'>

      </div>
      <div className='animation-bar left'>

      </div> */}
    </div>
  )
}

export default Animation;